@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base{
    li{
        @apply p-4
    }
    button{
        @apply text-white border bg-indigo-600 border-indigo-600
        hover:bg-transparent hover:text-indigo-600 rounded-md
    }
}